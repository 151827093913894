import React from "react";

interface PressProps {
  pressNodes: Array<{
    articleTitle: string;
    publishingDate: string;
    thumbnail: { url: string };
    fullArticleLink: string;
  }>;
}

const Press: React.FC<PressProps> = ({ pressNodes }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 lg:grid-cols-4 p-35px px-100px">
      {pressNodes.slice(0, 4).map((value, index) => {
        // Format the publishing date outside the JSX
        const featuredformattedDate = new Date(
          value.publishingDate
        ).toLocaleString("en-IN", {
          month: "long",
          day: "numeric",
          year: "numeric",
        });

        const parts = featuredformattedDate.split(" ");
        const feature_monthInCamelCase =
          parts[1].charAt(0).toUpperCase() + parts[1].slice(1).toLowerCase();
        return (
          <a
            key={index}
            href={value.fullArticleLink}
            target="_blank"
            className="page-1 hidden"
            rel="noreferrer"
          >
            <div className="bg-white-1 rounded-lg lg:m-15px md:m-10px m-10px min-h-[20rem] lg:min-h-[26rem]">
              <img
                src={value.thumbnail?.url}
                alt={value.articleTitle}
                className="w-full rounded-tl-lg rounded-tr-lg"
              />
              <div className="p-30px flex flex-col justify-between h-full">
                <div className="flex justify-between text-14 text-grey mb-5px">
                  {/* Render the formatted date here */}
                  <p>{`${parts[0]} ${feature_monthInCamelCase} ${parts[2]}`}</p>
                </div>
                <p className="mb-10px h-70px overflow-hidden">
                  <span className="mb-10px h-60px">{value.articleTitle}</span>
                </p>
              </div>
            </div>
          </a>
        );
      })}
    </div>
  );
};

export default Press;
